import { useTheme } from "@mui/material/styles";

export default function CompassLogo() {
  const theme = useTheme();
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="22px"
      height="20px"
      viewBox="0 0 576 512"
      enableBackground="new 0 0 576 512"
    >
      <path
        fill={theme?.palette?.neutral?.main}
        d="M288.5,3C148.5,3,35,116.5,35,256.5S148.5,510,288.5,510,542,396.5,542,256.5,428.5,3,288.5,3Zm0,464.35C172.05,467.35,77.65,373,77.65,256.5S172.05,45.65,288.5,45.65s210.85,94.4,210.85,210.85S405,467.35,288.5,467.35Z"
      />
      <path
        fill={theme?.palette?.neutral?.main}
        d="M223.76,295.26c-.15,2.73.34,18.16,5.7,31.62,4.21,11.76,13.18,20.13,24.32,25.67,17.71,8.81,36.63,8.88,55.77,7,12.36-1.2,23.8-4.62,33.81-12.34s15.26-27,12.18-39.16C351.12,290.63,337.93,284.71,323,281c-15.7-3.87-31.82-6.09-47.45-10.17C258.8,266.47,242,261.73,226,255.38c-19.54-7.73-34.64-21.26-39.05-42.73-5.28-25.68-.41-49.59,20-67.52,8.63-7.57,19.4-13.32,30.06-17.86,18.87-8,39.19-9.08,59.4-7.57,27.78,2.08,53.28,10.59,73.67,30.61A85.09,85.09,0,0,1,393.46,192c.56,2.38,1.15,4.76,1.92,7.07a18,18,0,0,1,.62,4.68s-4.63.69-6.66,1c-12.59,1.78-25.22,3.27-37.86,4.64l-3,.38s.2-2.51.21-2.87c.23-25-13.57-40.05-35.53-47.78A111.72,111.72,0,0,0,277.71,153c-16.87-.16-32.61,4.87-42.8,20-11,16.32-6.8,34.18,10.78,43,9.49,4.77,19.82,8.13,30.05,11.14,13.9,4.09,28.12,7.09,42.12,10.85,11.65,3.13,23.45,6,34.72,10.23,18.78,7,35.26,17.74,43.79,36.82,8.2,18.33,7.54,37.1-.54,55.53-8.77,20-24.19,32.94-43.66,41.83-25.11,11.47-51.63,12.53-78.42,10.52-17.92-1.35-35.37-5.55-51.35-14.09a82.09,82.09,0,0,1-37.7-41.09c-4.46-10.5-6.77-21.92-9.88-33-.09-.33-.45-2.09-.45-2.09s1.75-.34,2.11-.39c10.66-1.69,21.36-3.17,32-4.75C213.53,296.83,220.42,295.8,223.76,295.26Z"
      />
    </svg>
  );
}
