import { useSelectedTheme } from "./services/contexts/themeContext";
import { useUser } from "./services/contexts/userContext";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

//Components
import Nav from "./components/nav/Nav";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import Moods from "./components/pages/Moods";
import Badges from "./components/pages/Badges";
//import Covid from "./components/pages/Covid";

import Goals from "./components/pages/Goals";
import GoalsActive from "./components/common/GoalsActive";
import GoalsCompleted from "./components/common/GoalsCompleted";
import GoalForm from "./components/pages/GoalForm";

import Activities from "./components/pages/Activities";
import ActivityDetails from "./components/common/ActivityDetails";
import ActivitiesEnrolled from "./components/common/ActivitiesEnrolled";
import ActivitiesWaitlist from "./components/common/ActivitiesWaitlist";
import ActivitiesEligible from "./components/common/ActivitiesEligible";
import ActivitiesHistory from "./components/common/ActivitiesHistory";

import Interests from "./components/pages/Interests";
import InterestsRate from "./components/pages/InterestsRate";

import Surveys from "./components/pages/Surveys";
// import Buses from "./components/pages/Buses";
//import Attendance from "./components/pages/Attendance";
//import Courses from "./components/pages/Courses";
import Reports from "./components/pages/Reports";
import Events from "./components/pages/Events";
import Personalization from "./components/pages/Personalization";
import MyJourney from "./components/pages/MyJourney";
import Forms from "./components/pages/Forms";
import Help from "./components/pages/Help";
import LoadingUser from "./components/pages/LoadingUser";
import InvalidUser from "./components/pages/InvalidUser";
import Register from "./components/pages/Register";
import Eligiblity from "./components/pages/Eligiblity";
import NotFound404 from "./components/pages/NotFound404";

function App() {
  const { selectedTheme } = useSelectedTheme();
  const { user, userLoading, userQuery } = useUser();

  const isAllowedUser =
    user?.isGuardian === true ||
    (user?.isStudent === true &&
      (user?.studentDetails?.isSecondary === true ||
        Boolean(process.env.REACT_APP_ALLOW_NON_SECONDARY) === "true"));

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(selectedTheme))}>
      <CssBaseline />
      <BrowserRouter>
        <AuthenticatedTemplate>
          {/* Loading User */}
          {userLoading && (
            <Routes>
              <Route path="*" element={<Nav />}>
                <Route
                  path="*"
                  element={<LoadingUser userQuery={userQuery} />}
                />
              </Route>
            </Routes>
          )}

          {/* Invalid User */}

          {userLoading === false && user?.validUser === false && (
            <Routes>
              <Route path="*" element={<InvalidUser />} />
            </Routes>
          )}
          {/* Non Secondary Students */}
          {!isAllowedUser && (
            <Routes>
              <Route path="*" element={<Nav />}>
                <Route path="*" element={<Eligiblity />} />
              </Route>
            </Routes>
          )}

          {/* Normal Routes */}
          {isAllowedUser && userLoading === false && user?.validUser && (
            <Routes>
              <Route path="/" element={<Nav />}>
                <Route path="/" element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="moods" element={<Moods />} />
                <Route path="badges" element={<Badges />}>
                  <Route path=":badgeId" element={<Badges />} />
                </Route>
                {/* <Route path="covid" element={<Covid />} /> */}

                {/* Goals */}
                <Route path="goals" element={<Goals />}>
                  <Route path="" element={<GoalsActive />} />
                  <Route path="completed" element={<GoalsCompleted />} />
                </Route>
                <Route path="goals/new" element={<GoalForm />} />
                <Route path="goals/badge/:badgeId" element={<GoalForm />} />
                <Route
                  path="goals/interests/:interestId"
                  element={<GoalForm />}
                />
                <Route path="goals/:goalId" element={<GoalForm />} />

                {/* Interests */}
                <Route path="interests" element={<Interests />} />
                <Route path="interests/:category" element={<InterestsRate />} />
                <Route
                  path="interests/:category/:interestId"
                  element={<InterestsRate />}
                />

                <Route path="interests" element={<Interests />} />
                {/* <Route path="attendance" element={<Attendance />} />
                <Route path="/attendance/:StudentID" element={<Attendance />} /> */}
                {/* <Route path="courses" element={<Courses />} /> */}
                <Route path="reports" element={<Reports />} />

                <Route path="events" element={<Events />} />
                <Route path="events/:eid" element={<Events />} />

                <Route path="personalization" element={<Personalization />} />
                <Route path="journey" element={<MyJourney />} />

                {/* Activities */}
                <Route path="activities" element={<Activities />}>
                  <Route path="" element={<ActivitiesEnrolled />} />
                  <Route path="waitlist" element={<ActivitiesWaitlist />} />
                  <Route path="eligible" element={<ActivitiesEligible />} />
                  <Route path="history" element={<ActivitiesHistory />} />
                </Route>
                <Route
                  path="activities/:programId"
                  element={<ActivityDetails />}
                />

                <Route path="surveys" element={<Surveys />} />
                <Route path="surveys/:surveyId" element={<Surveys />} />
                {/* <Route path="buses" element={<Buses />} /> */}
                <Route path="forms" element={<Forms />} />
                <Route path="help" element={<Help />} />
                <Route path="*" element={<NotFound404 />} />
              </Route>
            </Routes>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Routes>
        </UnauthenticatedTemplate>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
