import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useWithdrawParticipant } from "../../services/hooks/participantsHook";

export default function ActivityWithdraw(props) {
  const { details, open, onClose } = props;
  const { activeStudent } = useActiveStudent();

  const [value, setValue] = useState("");
  const [otherText, setOtherText] = useState("");
  const [note, setNote] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setNote(value === "Other" ? `Other: ${otherText}` : value);
  }, [value, otherText]);

  const handleOtherChange = (event) => {
    setOtherText(event.target.value);
  };
  const withdrawParticipant = useWithdrawParticipant(
    {
      participantId: details?.participantId,
      note: note,
    },
    onClose
  );

  const submitWithdraw = () => {
    withdrawParticipant.mutate();
    setValue("");
    setOtherText("");
    setNote("");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5">
          Are you sure you want to withdraw {activeStudent?.firstName} from{" "}
          {details?.title ?? details?.activityName}?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ pb: "2em" }}>
          You may not be able to register again if the registration window
          has&nbsp;ended.
        </Typography>
        <Typography>Please select a reason for withdrawal:</Typography>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value="Student not interested"
            control={<Radio />}
            label="Student not interested"
          />
          <FormControlLabel
            value="Scheduling conflict"
            control={<Radio />}
            label="Scheduling conflict"
          />
          <FormControlLabel
            value="Transportation challenges"
            control={<Radio />}
            label="Transportation challenges"
          />
          <FormControlLabel value="Other" control={<Radio />} label="Other" />
        </RadioGroup>
        {value === "Other" && (
          <TextField
            label="Please specify"
            value={otherText}
            onChange={handleOtherChange}
            inputProps={{ maxLength: 50 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setValue("");
            setOtherText("");
            setNote("");
          }}
          disabled={withdrawParticipant?.isLoading}
        >
          {" "}
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            withdrawParticipant?.isLoading ||
            (value === "Other" && otherText === "") ||
            otherText === null
          }
          onClick={submitWithdraw}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
