import React, { useEffect, useState } from "react";
import { Typography, Skeleton, Grid } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useGetWaitlistByStudentId } from "../../services/hooks/waitlistHook";
import ActivityListItem from "./ActivityListItem";

export default function ActivitiesWaitlist() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();
  const [programs, setPrograms] = useState();
  const waitlistQuery = useGetWaitlistByStudentId(activeStudent?.studentId);
  const waitlist = waitlistQuery?.data?.data;

  // const query = useCompassEnrolledPrograms(activeStudent?.studentId);
  // const programs = query?.data?.data;

  useEffect(() => {
    setPrograms(waitlist?.map((wl) => wl.program));
  }, [waitlist]);

  return (
    <>
      {/* {query.isError && (
        <Error message="There was an issue retrieving current activities." />
      )} */}
      {waitlistQuery.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!waitlistQuery.isLoading && programs?.length === 0 && (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                mt: 3,
                color: theme?.palette?.text?.secondary,
              }}
              gutterBottom
            >
              <em>
                When{" "}
                {user?.role === "guardian"
                  ? `${activeStudent?.firstName} is`
                  : "you are"}{" "}
                on the waitlist for any currently-running activities, you'll see
                a list of them&nbsp;here...
              </em>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FontAwesomeIcon
              icon={faList}
              size="10x"
              color={alpha(theme?.palette?.text?.primary ?? "#777", 0.25)}
            />
          </Grid>
        </Grid>
      )}
      {!waitlistQuery.isLoading &&
        programs?.length > 0 &&
        programs?.map((p, index) => {
          return <ActivityListItem program={p} key={index} />;
        })}
    </>
  );
}
