import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function ActivitiesAbout() {
  const theme = useTheme();
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);
  const { activeStudent } = useActiveStudent();

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const introStyles = {
    justifyContent: "space-between",
    alignItems: "center",
    fontStyle: "italic",
    flexWrap: "nowrap",
  };
  const howToStyles = {
    mt: 2,
    "& p": {
      marginBottom: "1rem",
    },
    "& h4": {
      marginBottom: "1rem",
    },
  };
  const actionsStyles = {
    borderTop: `1px solid ${
      theme?.palette?.neutral?.main
        ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Activities
      </Button>
      <Dialog open={aboutDialogIsOpen} onClose={handleAboutClose}>
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> About Activities
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={introStyles}>
            <Grid item>
              <Typography>
                Tacoma Public Schools and dozens of community partners have come
                together to offer{" "}
                {activeStudent?.isSecondary ? "Club Beyond" : "Beyond the Bell"}
                . Included are clubs, activities,{" "}
                {!activeStudent?.isSecondary && "sports, "} field trips and
                more. Choose from options for before and after school,
                in-service days, and school&nbsp;breaks.
              </Typography>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={fasBeyondTheBell}
                color={theme?.palette?.secondary?.main}
                size="4x"
                style={{ margin: "0 1rem" }}
              />
            </Grid>
          </Grid>
          <Box sx={howToStyles}>
            <Typography variant="h4" color="primary">
              How to use Compass Activities
            </Typography>
            <Typography>
              <strong>Discover</strong>
              <br />
              Students and parents/guardians can discover activities by browsing
              through the eligible activities. These programs are typically
              specific to one school and may target specific groups, for
              instance, 4th and 5th graders or students receiving Special
              Ed&nbsp;services.
            </Typography>
            <Typography>
              <strong>Register</strong>
              <br />
              Click an activity in the list of eligible activities to see all
              its details.{" "}
              <em style={{ color: theme?.palette?.tertiary?.text }}>
                Only parents/guardians can register a student for an activity
              </em>{" "}
              by clicking the big Register button on its details&nbsp;page.
            </Typography>
            <Typography>
              If a program is full, you can add your student to the waitlist.
            </Typography>
            <Typography>
              <strong>Manage</strong>
              <br />
              The Current and History tabs show all activities a student has
              been registered for. You can review the dates of the activity and
              all its other details.
            </Typography>
            <Typography>
              If needed, parents/guardians can also withdraw students from
              current programs or remove them from&nbsp;waitlists.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
