import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";

import ReactECharts from "echarts-for-react";
import {
  useGetStudentMoodCountByMonth,
  useGetMoodsCount,
} from "../../services/hooks/moodsHook";
require("@gouch/to-title-case");

export default function MoodsStats() {
  const theme = useTheme();
  const moodsCountResponse = useGetMoodsCount();
  const moodColorCount = moodsCountResponse?.data?.data;

  const journeyMoodDetails = useGetStudentMoodCountByMonth();
  const moodCounts = journeyMoodDetails?.data?.data;

  const months = [
    {
      Name: "Sep",
      Value: 9,
    },
    {
      Name: "Oct",
      Value: 10,
    },
    {
      Name: "Nov",
      Value: 11,
    },
    {
      Name: "Dec",
      Value: 12,
    },
    {
      Name: "Jan",
      Value: 1,
    },
    {
      Name: "Feb",
      Value: 2,
    },
    {
      Name: "Mar",
      Value: 3,
    },
    {
      Name: "Apr",
      Value: 4,
    },
    {
      Name: "May",
      Value: 5,
    },
    {
      Name: "Jun",
      Value: 6,
    },
    {
      Name: "Jul",
      Value: 7,
    },
    {
      Name: " Aug",
      Value: 8,
    },
  ];

  const colorSeries = useMemo(() => {
    //Map through moodCounts
    //Create an inner array for each color
    return moodCounts?.map((c) => {
      return {
        color: c.color.toTitleCase(),
        counts: months.map(
          (m, i) =>
            moodCounts
              ?.find((e) => e.color === c.color)
              ?.monthCounts?.find((c) => c.month === m.Value)?.count ?? 0
        ),
      };
    });
  }, [moodCounts, months]);

  const moodsStatChart = {
    title: {
      text: "My moods journey".toTitleCase(),
      textStyle: {
        color: theme?.palette?.text?.primary,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    dataset: {
      source: [
        ["Months", ...months.map((m) => m.Name)],
        colorSeries?.length > 0 && [
          colorSeries[0].color,
          ...colorSeries[0].counts,
        ],
        colorSeries?.length > 1 && [
          colorSeries[1].color,
          ...colorSeries[1].counts,
        ],
        colorSeries?.length > 2 && [
          colorSeries[2].color,
          ...colorSeries[2].counts,
        ],
        colorSeries?.length > 3 && [
          colorSeries[3].color,
          ...colorSeries[3].counts,
        ],
      ],
    },
    textStyle: {
      color: theme?.palette?.text?.primary,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      gridIndex: 0,
    },
    grid: {
      top: "55%",
    },
    series: [
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "Color zone",
        type: "pie",
        radius: "30%",
        center: ["50%", "25%"],
        zlevel: 1,
        emphasis: {
          focus: "self",
        },
        data: moodColorCount?.map((color) => {
          return {
            value: color?.count,
            name: color?.color?.toTitleCase(),
            itemStyle: {
              color: theme?.palette?.moods[`${color?.color}Zone`]?.main,
            },
          };
        }),
        label: {
          textShadowColor: "transparent",
          color: theme?.palette?.text?.primary,
        },
        encode: {
          itemName: "Months",
          value: "June",
          tooltip: {
            trigger: "item",
          },
        },
      },
    ],
  };

  return (
    <>
      <ReactECharts option={moodsStatChart} />
    </>
  );
}
