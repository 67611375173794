import React from "react";
import { Skeleton, Typography, List, ListItemButton } from "@mui/material";
import Error from "./Error";
import GoalListItem from "./GoalListItem";
import { useGetCompassActiveGoals } from "../../services/hooks/studentGoalsHook";
import TrailSlideUpFadeIn from "../animations/TrailSlideUpFadeIn";

export default function GoalsActive() {
  const query = useGetCompassActiveGoals();
  const goals = query?.data?.data;

  const renderSkeltons = () => {
    return (
      <>
        <ListItemButton disabled divider>
          <Skeleton variant="rect" width="100%" height={50} sx={{ my: 2 }} />
        </ListItemButton>
        <ListItemButton disabled divider>
          <Skeleton variant="rect" width="100%" height={50} sx={{ my: 2 }} />
        </ListItemButton>
      </>
    );
  };

  return (
    <>
      {query.isLoading && renderSkeltons()}
      {query.isError && (
        <Error
          message="There was an error retrieving your active goals."
          query={query}
        />
      )}
      {query.isSuccess && (
        <List>
          <TrailSlideUpFadeIn>
            {goals?.map((goal) => (
              <GoalListItem key={goal.studentGoalId} goal={goal} />
            ))}
          </TrailSlideUpFadeIn>
        </List>
      )}
      {query.isSuccess && goals?.length === 0 && (
        <Typography>No active goals found.</Typography>
      )}
    </>
  );
}
