import React, { useState, useMemo, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useGetCompassUser } from "../hooks/compassSignInsHook";
import { detect } from "detect-browser"; // very lightweight, simple browser detection (used for TranslationInfo dialog), usually feature detection is more appropriate if needed

const UserContext = React.createContext();

export function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserContextProvider`);
  }
  return context;
}

export function UserContextProvider(props) {
  const msal = useMsal();

  const [role, setRole] = useState();

  const userQuery = useGetCompassUser(msal?.accounts?.length > 0);
  const userData = userQuery?.data?.data;
  const userLoading = userQuery?.isLoading;

  const user = useMemo(() => {
    return {
      oid: msal?.accounts[0]?.idTokenClaims?.oid,
      name: titleCase(msal?.accounts[0]?.name),
      email: msal?.accounts[0]?.username?.toLowerCase(),
      username: msal?.accounts[0]?.username?.toLowerCase()?.split("@")?.[0],
      domain: msal?.accounts[0]?.username?.toLowerCase()?.split("@")?.[1],
      role: role,
      isStudent: userData?.isStudent,
      studentDetails: userData?.studentDetails,
      isEmployee: userData?.isEmployee,
      isGuardian: userData?.isGuardian,
      students: userData?.students,
      isFirstLogin: userData?.isFirstLogin,
      settings: userData?.settings && JSON.parse(userData?.settings),
      validUser:
        !!userData?.isStudent ||
        !!userData?.isEmployee ||
        !!userData?.isGuardian,
      browser: detect(),
    };
  }, [msal?.accounts, userData, role]);

  useEffect(() => {
    if (role == null && userData != null) {
      if (userData?.isGuardian) {
        setRole("guardian");
      } else {
        setRole("student");
      }
    }
  }, [msal, userData]);

  const value = { user, userLoading, userQuery, setRole };
  return <UserContext.Provider value={value} {...props} />;
}

function titleCase(str) {
  if (!!str && str?.toUpperCase() === str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else return str;
}
