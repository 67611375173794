import React from "react";
import { Typography, Skeleton, Grid } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleHistoryCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCompassProgramHistory } from "../../services/hooks/programsHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";

export default function ActivitiesHistory() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();

  const query = useCompassProgramHistory(activeStudent?.studentId);
  const programs = query?.data?.data;

  return (
    <>
      {query.isError && (
        <Error message="There was an issue retrieving program history." />
      )}
      {query.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!query.isLoading && programs?.length === 0 && (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                mt: 3,
                color: theme?.palette?.text?.secondary,
              }}
              gutterBottom
            >
              <em>
                If{" "}
                {user?.role === "guardian"
                  ? `${activeStudent?.firstName} was`
                  : "you were"}{" "}
                previously enrolled in any activities that have now ended, you'd
                see a list of them&nbsp;here...
              </em>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FontAwesomeIcon
              icon={faRectangleHistoryCircleUser}
              size="10x"
              color={alpha(theme?.palette?.text?.primary ?? "#777", 0.25)}
            />
          </Grid>
        </Grid>
      )}
      {!query.isLoading &&
        programs?.length > 0 &&
        programs?.map((p, index) => {
          return <ActivityListItem program={p} key={index} />;
        })}
    </>
  );
}
