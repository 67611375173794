import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PossibleExternalLinkButton from "../common/PossibleExternalLinkButton";

export default function HeroStandard(props) {
  const { title, subtitle, description, image, url, linkText } = props;
  const theme = useTheme();
  const extraSmallScreen = useMediaQuery(theme?.breakpoints.down("sm"));

  // Styles
  const imageWrapStyles = {
    float: extraSmallScreen ? "none" : "left",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
    marginBottom: "1rem",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h1"
          sx={{ maxWidth: "100% !important" }}
        >
          <span style={imageWrapStyles}>{image}</span>
          {title}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          sx={{ maxWidth: "100% !important" }}
        >
          {subtitle}
        </Typography>
        <Typography sx={{ maxWidth: "100% !important" }}>
          {description}{" "}
          <PossibleExternalLinkButton url={url} linkText={linkText} />
        </Typography>
      </Grid>
    </Grid>
  );
}
