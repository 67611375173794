import React, { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  MobileStepper,
  useMediaQuery,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { getFirstName } from "../../services/utilities";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import { useNavigate } from "react-router";
import { blob } from "../../services/utilities";
import useMousePosition from "./../../services/hooks/useMousePosition";
import CompassLogo from "../images/CompassLogo";
import TrailFadeIn from "../animations/TrailFadeIn";
import AboutCompassMoods from "./AboutCompassMoods";
import AboutCompassGoals from "./AboutCompassGoals";
import AboutCompassBadges from "./AboutCompassBadges";
import AboutCompassActivities from "./AboutCompassActivities";
import AboutCompassPersonalization from "./AboutCompassPersonalization";
import AboutCompassGuardians from "./AboutCompassGuardians";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faSmile,
  faFlag,
  faShieldAlt,
  faUserEdit,
  faCalendarCheck,
  faHome,
  faFileChartColumn,
} from "@fortawesome/pro-solid-svg-icons";
// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import useLocalStorage from "../../services/hooks/useLocalStorage";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function AboutCompass() {
  const { user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const firstName = getFirstName(user?.name);
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [hasDialogBeenOpened, setHasDialogBeenOpened] = useLocalStorage(
    "aboutDialogIsOpen",
    false
  );
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [numberOfSteps] = useState(user?.role === "student" ? 7 : 5);

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };
  const handleNext = () => {
    if (activeStep === numberOfSteps - 1) {
      aboutDialogIsOpen(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (aboutDialogIsOpen) {
      setHasDialogBeenOpened(true);
    }
  }, [aboutDialogIsOpen]);

  useEffect(() => {
    if (user?.isFirstLogin && !hasDialogBeenOpened) {
      setAboutDialogIsOpen(true);
    }
  }, [user?.isFirstLogin]);

  // Styles
  const slideStyles = {
    ...commonStyles.slideStyles,
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    position: "absolute",
    right: 4,
    top: 4,
    zIndex: 1000,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    mixBlendMode: "difference",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const actionsStyles = {
    borderTop: `1px solid ${
      theme?.palette?.neutral?.main
        ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
    padding: 0,
    justifyContent: "center",
  };
  const navButton = {
    height: "2em",
    width: "2em",
  };

  const introSlide = (
    <Box sx={slideStyles}>
      <Typography
        component="h1"
        variant="h1"
        sx={{
          fontWeight: 100,
        }}
      >
        Hello, {firstName ?? "there"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 24px)",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TrailFadeIn delay={300}>
            <CompassLogo
              tagline={false}
              scale={!!largeScreen ? 1.7 : 1}
              alt={theme?.palette?.mode === "light" ? true : false}
            />
            <Typography
              component="h2"
              sx={{
                marginTop: "-2rem",
                fontSize: !!largeScreen
                  ? "1.75rem !important"
                  : "1.5rem !important",
              }}
            >
              {user?.role === "student" ? (
                <em>Compass helps you get where you want to&nbsp;go...</em>
              ) : (
                <em>
                  Compass helps you point your kids in the
                  right&nbsp;direction...
                </em>
              )}
            </Typography>
          </TrailFadeIn>
        </Box>
        <Box>
          <Grid
            container
            spacing={1}
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <TrailFadeIn delay={600}>
              <Grid item>
                <Typography>
                  {user?.role === "student" ? (
                    <span>
                      Find afterschool activities, set goals, get tips based on
                      your mood, earn badges and&nbsp;more.
                    </span>
                  ) : (
                    <span>
                      View your student's {/*attendance and */} schedules,
                      register for afterschool activities and&nbsp;more.
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: "right" }}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem !important" }}
                >
                  <strong>Take&nbsp;a&nbsp;look around...</strong>
                </Typography>
                <Button
                  variant="outlined"
                  endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Grid>
            </TrailFadeIn>
          </Grid>
        </Box>
      </Box>
    </Box>
  );

  /* Explore slide ***********************/
  const containerDiameter = largeScreen ? 450 : 277;
  const iconDiameter = 48;
  const compassDiameter = containerDiameter - (iconDiameter + 16) * 2;
  const mousePosition = useMousePosition();
  const [arrowCenterX, setArrowCenterX] = useState();
  const [arrowCenterY, setArrowCenterY] = useState();
  // Set arrow coordinates to middle of screen
  const getArrowPosition = () => {
    const x = window.innerWidth / 2;
    setArrowCenterX(x);
    const y = window.innerHeight / 2;
    setArrowCenterY(y);
  };
  // Get the arrows coordiantes
  useEffect(() => {
    getArrowPosition();
  }, []);
  // Update the arrows coordinates when the window is resized
  useEffect(() => {
    window.addEventListener("resize", getArrowPosition);
  }, []);

  function twisterMath(x, y, xShapeCenter, yShapeCenter) {
    return Math.atan2(x - xShapeCenter, -(y - yShapeCenter)) * (180 / Math.PI);
  } // https://codepen.io/frost084/details/MOEpog#forks

  // Styles for Explore slide
  const iconStyles = {
    width: iconDiameter,
    height: iconDiameter,
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: `-${iconDiameter / 2}px`,
    background: alpha(theme.palette.text.secondary, 0.05),
  };
  const containerStyles = {
    height: containerDiameter,
    width: containerDiameter,
    margin: "0 auto",
    position: "relative",
    borderRadius: "50%",
  };
  const compassStyles = {
    height: compassDiameter,
    width: compassDiameter,
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: `-${compassDiameter / 2}px`,
    borderRadius: "50%",
    background: `${alpha(
      theme.palette.text.secondary,
      0.05
    )} url(${blob}compass-ring.svg) no-repeat center center`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const compassGlassStylesOuter = {
    position: "absolute",
    overflow: "hidden",
    height: "92%",
    width: "92%",
    borderRadius: "50%",
    zIndex: 2,
  };
  const compassGlassStylesInner = {
    position: "absolute",
    height: "300%",
    width: "300%",
    borderRadius: "50%",
    top: "0%",
    left: "-180%",
    background:
      "linear-gradient(33deg, rgba(255,255,255,0) 80%, rgba(255,255,255,0.2) 100%)",
  };
  const arrowStyles = {
    height: compassDiameter,
  };

  const studentButtons = [
    { icon: faHome, url: "/" },
    { icon: faSmile, url: "/moods" },
    { icon: faFlag, url: "/goals" },
    { icon: faShieldAlt, url: "/badges" },
    //{ icon: faBook, url: "/courses" },
    { icon: faFileChartColumn, url: "/reports" },
    { icon: fasBeyondTheBell, url: "/activities" },
    { icon: faUserEdit, url: "/personalization" },
  ];
  const guardianButtons = [
    { icon: faHome, url: "/" },
    { icon: fasBeyondTheBell, url: "/activities" },
    { icon: faCalendarCheck, url: "/attendance" },
    // { icon: faBook, url: "/courses" },
    { icon: faFileChartColumn, url: "/reports" },
    { icon: faUserEdit, url: "/personalization" },
  ];
  const degrees =
    user?.role === "student"
      ? 360 / studentButtons.length
      : 360 / guardianButtons.length;
  const shift = 8; // degrees to shift the buttons, so that the end result looks more interesting

  const renderButtons = (btns) => {
    let buttons = btns.map((btn, index) => {
      return (
        <IconButton
          key={index}
          onClick={() => {
            if (index === 0) {
              handleAboutClose();
            } else navigate(btn.url);
          }}
          sx={{
            ...iconStyles,
            transform: `rotate(${degrees * index + shift}deg) translate(${
              containerDiameter / 2 - iconDiameter / 2
            }px) rotate(-${degrees * index + shift}deg)`,
            // this first rotates the icon,
            // then moves it along its now angled x-axis the distance of the container's radius,
            // and then rotates it back
          }}
        >
          <FontAwesomeIcon icon={btn.icon} />
        </IconButton>
      );
    });
    return buttons;
  };

  const exploreSlide = (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1">
        Explore!
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 32px)",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={containerStyles}>
          {renderButtons(
            user?.role === "student" ? studentButtons : guardianButtons
          )}
          {/* TODO: maybe enlarge icons and show label on hover */}
          <Box sx={compassStyles}>
            <Box sx={compassGlassStylesOuter}>
              <Box sx={compassGlassStylesInner}></Box>
            </Box>
            <img
              src={`${blob}compass-arrow.svg`}
              alt="compass arrow"
              style={{
                ...arrowStyles,
                transform: `rotate(${twisterMath(
                  mousePosition.x,
                  mousePosition.y,
                  arrowCenterX,
                  arrowCenterY
                )}deg)`,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
  /************************************/

  const studentContent = [
    introSlide,
    <AboutCompassMoods />,
    <AboutCompassGoals />,
    <AboutCompassBadges />,
    //<AboutCompassCourses />,
    <AboutCompassActivities />,
    <AboutCompassPersonalization />,
    exploreSlide,
  ];
  const guardianContent = [
    introSlide,
    <AboutCompassActivities />,
    //<AboutCompassAttendance />,
    //<AboutCompassCourses />,
    <AboutCompassPersonalization />,
    <AboutCompassGuardians />,
    exploreSlide,
  ];

  const getContent = () => {
    return user?.role === "student"
      ? studentContent[activeStep]
      : guardianContent[activeStep];
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        About
        <br />
        Compass
      </Button>
      <Dialog
        open={aboutDialogIsOpen}
        onClose={handleAboutClose}
        sx={{ "& .MuiDialog-paper": { border: "none" } }}
      >
        <DialogContent sx={{ p: 0 }}>
          <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
          {getContent()}
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <MobileStepper
            position={"static"}
            steps={numberOfSteps}
            activeStep={activeStep}
            nextButton={
              <IconButton
                onClick={handleNext}
                disabled={activeStep === numberOfSteps - 1}
                aria-label="Next"
                sx={navButton}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </IconButton>
            }
            backButton={
              <IconButton
                onClick={handleBack}
                disabled={activeStep === 0}
                aria-label="Back"
                sx={navButton}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>
            }
            sx={{ background: "transparent" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
