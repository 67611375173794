import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
} from "@mui/lab";
import { useMemo } from "react";
import { Typography, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import { formatDate } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulbOn,
  faFlagCheckered,
  faCircleCheck,
  faCircleSmall,
} from "@fortawesome/pro-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function GoalMilestonesTimeline(props) {
  const theme = useTheme();
  const {
    goalTargetDate,
    goalCompletedDate,
    goalCreatedDateTime,
    goalMilestones,
  } = props;
  const commonStyles = useCommonStyles();

  const isToday = require("dayjs/plugin/isToday");
  const isTomorrow = require("dayjs/plugin/isTomorrow");
  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  const milestoneItems = useMemo(() => {
    let items = [];

    //Only add "today" node if not passed due date
    if (!dayjs(goalTargetDate).isBefore(dayjs())) {
      items.push({
        date: dayjs().format("YYYY-MM-DD"),
        label: "Today",
        sublabel: "",
        orderPriority: 0,
        icon: (
          <FontAwesomeIcon
            icon={faCircleSmall}
            size="2x"
            color={theme.palette.text.secondary}
          />
        ),
        uniqueKey: -10,
      });
    }

    //Start
    items.push({
      date: dayjs(goalCreatedDateTime).format("YYYY-MM-DD"),
      label: "Goal Created",
      sublabel: "",
      complete: true,
      orderPriority: -1,
      icon: (
        <FontAwesomeIcon
          icon={faLightbulbOn}
          color={theme.palette.success.text}
          size="2x"
        />
      ),
      uniqueKey: -11,
    });
    //End
    items.push({
      date: dayjs(goalTargetDate).format("YYYY-MM-DD"),
      label: "Target Date",
      sublabel: goalCompletedDate != null && "Completed",
      complete: goalCompletedDate != null,
      orderPriority: 2,
      icon: (
        <FontAwesomeIcon
          icon={faFlagCheckered}
          color={
            goalCompletedDate
              ? theme.palette.success.text
              : theme.palette.text.secondary
          }
          size="2x"
        />
      ),
      uniqueKey: -12,
    });

    goalMilestones?.forEach((milestone) => {
      const deadline = dayjs(milestone.mileStoneDate);
      const diff = deadline.diff(dayjs(), "day");
      items.push({
        date: dayjs(milestone.mileStoneDate).format("YYYY-MM-DD"),
        label: milestone.milestoneDescription,
        sublabel: "",
        complete: milestone.complete,
        orderPriority: 1,
        icon: milestone.complete ? (
          <FontAwesomeIcon
            icon={faCircleCheck}
            size="2x"
            color={theme.palette.success.main}
          />
        ) : diff < 0 ? (
          <FontAwesomeIcon
            icon={faCircle}
            size="2x"
            color={theme.palette.error.text}
          />
        ) : deadline.isToday() || deadline.isTomorrow() ? (
          <FontAwesomeIcon
            icon={faCircle}
            size="2x"
            color={theme.palette.warning.text}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCircle}
            size="2x"
            color={theme.palette.text.secondary}
          />
        ),
        uniqueKey: milestone.studentGoalMilestoneId ?? milestone.uniqueKey,
      });
    });
    return items
      .sort((a, b) => {
        if (dayjs(a.date).isSame(dayjs(b.date))) {
          return a.orderPriority - b.orderPriority;
        }
        return dayjs(a.date) - dayjs(b.date);
      })
      .map((item) => {
        item.date = formatDate(item.date);
        return item;
      });
  }, [goalMilestones, goalCompletedDate, goalTargetDate]);

  // Styles
  const itemStyles = {
    ...commonStyles.textOverBackground,
  };
  const dateLabelStyles = {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem !important",
  };
  const timelineTextStyles = {
    overflowWrap: "break-word",
    width: "200px",
  };
  const iconStyles = {
    m: "10px auto",
    transition: `all ${theme?.transitions?.easing?.easeInOut} ease-in-out`,
    "&:before": {
      content: '" "',
      position: "absolute",
      background: `radial-gradient(${alpha(
        theme?.palette?.background?.default ?? "#777",
        0.75
      )} 0%, ${alpha(
        theme?.palette?.background?.default ?? "#777",
        0.5
      )} 35%, ${alpha(
        theme?.palette?.background?.default ?? "#777",
        0.2
      )} 45%, transparent 65%) no-repeat`,
      width: "3rem",
      height: "3rem",
      borderRadius: "50%",
      zIndex: -1,
      left: "0.8rem",
      top: "0.5rem",
    },
  };

  return (
    <Timeline position="right">
      {milestoneItems?.map((ms, index) => (
        <TimelineItem
          key={ms.studentGoalMilestoneId ?? ms.uniqueKey}
          sx={itemStyles}
        >
          <TimelineOppositeContent
            style={{ flex: 0.01, padding: 0 }}
          ></TimelineOppositeContent>
          <TimelineSeparator sx={{ width: "3rem", flex: 0.4 }}>
            <TimelineDot
              sx={{
                ...iconStyles,
                background: ms.icon && "transparent",
                boxShadow: ms.icon && "none",
              }}
            >
              {ms.icon}
            </TimelineDot>
            {index !== milestoneItems?.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={timelineTextStyles}>
            <Typography>{ms.label}</Typography>
            <Typography sx={dateLabelStyles}>{ms.date}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
