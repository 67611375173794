import React from "react";
import {
  Box,
  Button,
  Grid,
  Drawer,
  useMediaQuery,
  Typography,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CompassLogo from "../images/CompassLogo";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { useTheme, darken } from "@mui/material/styles";
import MicrosoftLoginBtn from "../images/MicrosoftLogin";
import RegisterButton from "../images/RegisterButton";
import { useSpring, animated, config } from "react-spring";
import TranslationInfo from "../common/TranslationInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faGraduationCap,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function Login() {
  const { instance } = useMsal();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // Styles
  const wrapperStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
    width: "100vw",
  };

  const buttonWrapperStyles = {
    display: "flex",
    alignItems: "center",
  };

  const buttonStyles = {
    padding: 0,
    transition: "all 0.3s ease-in-out",
    borderRadius: "16px",
    "&:hover": {
      boxShadow: "0px 0px 25px 20px rgba(0,0,0,0.2)",
    },
  };
  const drawerButtonStyles = {
    padding: "4px",
    minWidth: "32px",
    width: "32px",
    borderRadius: 0,
    borderTopLeftRadius: "50%",
    borderTopRightRadius: "50%",
    color: theme?.palette?.text?.secondary,
    backgroundColor: darken(theme?.palette?.background?.default, 0.25),
    fontSize: "1.5rem",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: darken(theme?.palette?.background?.default, 0.25),
      boxShadow: "none",
      color: theme?.palette?.text?.primary,
    },
    "&:before": {
      position: "absolute",
      left: "-4px",
      bottom: 0,
      content: "''",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "0 0 4px 4px",
      borderColor: `transparent transparent ${darken(
        theme?.palette?.background?.default,
        0.25
      )} transparent`,
    },
    "&:after": {
      position: "absolute",
      right: "-4px",
      bottom: 0,
      content: "''",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "4px 0 0 4px",
      borderColor: `transparent transparent  transparent ${darken(
        theme?.palette?.background?.default,
        0.25
      )}`,
    },
  };
  const drawerOpenButtonStyles = {
    ...drawerButtonStyles,
    position: "absolute",
    bottom: "0",
  };
  const drawerCloseButtonStyles = {
    ...drawerButtonStyles,
    position: "absolute",
    top: "-32px",
    left: "calc(50% - 16px)",
    boxShadow: "none",
  };
  const drawerStyles = {
    position: "relative",
    "& .MuiDrawer-paper": {
      borderTop: "none",
      background: "none",
      backdropFilter: "none",
      overflow: "visible",
    },
  };
  const drawerWrapperStyles = {
    padding: theme.spacing(2),
    backgroundColor: darken(theme?.palette?.background?.default, 0.25),
    display: "flex",
    justifyContent: "center",
  };
  const drawerContentStyles = {
    maxWidth: "600px",
    paddingBottom: theme.spacing(2),
  };
  const footerStyles = {
    backgroundColor: darken(theme?.palette?.background?.default, 0.5),
    padding: theme.spacing(1),
  };

  // Animations
  const logoSpringProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -500 },
    delay: 200,
    config: config.gentle,
  });
  const buttonsSpringProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 600,
    config: config.gentle,
  });
  const AnimatedLogo = animated(Box);
  const AnimatedButtons = animated(Grid);

  return (
    <Box sx={wrapperStyles}>
      <Grid container justifyContent="space-evenly" sx={{}}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <AnimatedLogo style={logoSpringProps}>
            <CompassLogo theme={theme} scale={largeScreen ? 1.5 : 1.25} />
          </AnimatedLogo>
        </Grid>
        {/* Grid */}
        <AnimatedButtons style={buttonsSpringProps} item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item sx={buttonWrapperStyles}>
              <Button
                sx={buttonStyles}
                onClick={() => {
                  instance.loginRedirect(loginRequest).catch((e) => {
                    console.error(e);
                  });
                }}
              >
                <MicrosoftLoginBtn />
              </Button>
            </Grid>

            <Grid item sx={buttonWrapperStyles}>
              <Button sx={buttonStyles} component={RouterLink} to="register">
                <RegisterButton />
              </Button>
            </Grid>
          </Grid>
        </AnimatedButtons>
      </Grid>
      <Button
        variant="contained"
        onClick={toggleDrawer}
        sx={drawerOpenButtonStyles}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </Button>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        sx={drawerStyles}
      >
        <Button
          variant="contained"
          onClick={toggleDrawer}
          sx={drawerCloseButtonStyles}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Button>
        <Box sx={drawerWrapperStyles}>
          <Grid container sx={drawerContentStyles} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1">
                Here's some of what you can do&nbsp;here...
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2" color="secondary">
                <FontAwesomeIcon icon={fasBeyondTheBell} /> &nbsp;Beyond
                the&nbsp;Bell
              </Typography>
              <Typography variant="body2" gutterBottom>
                Learn about and register for before- and after-school clubs,
                activities, sports, and field&nbsp;trips.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" color="secondary">
                <FontAwesomeIcon icon={faUser} /> Guardians only
              </Typography>
              <Typography variant="body2" gutterBottom>
                Register your kids for after-school activities, make online
                payments without needing another login,&nbsp;etc.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" component="h2" color="secondary">
                <FontAwesomeIcon icon={faGraduationCap} /> Students only
              </Typography>
              <Typography variant="body2" gutterBottom>
                Find help for your social and emotional health, set and track
                goals, earn badges for achievements,&nbsp;etc.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="p"
                sx={{ textAlign: "center" }}
              >
                <em>...and more...</em>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={footerStyles}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="caption" component="p">
                <Link
                  href="https://www.tacomaschools.org"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Tacoma Public Schools
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <TranslationInfo />
            </Grid>
            <Grid item>
              <Typography variant="caption" component="p">
                <Link
                  href="https://www.tacomaschools.org/non-discrimination-statement"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Non-Discrimination Policy
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
