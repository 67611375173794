import React from "react";
import { Link, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltLeft } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";

export default function ActivitiesTitle() {
  const navigate = useNavigate();
  const { activeStudent } = useActiveStudent();
  const { programId } = useParams();
  const subtitle = activeStudent?.isSecondary
    ? ": Club Beyond"
    : ": Beyond the Bell";

  // Styles
  const wrapperStyles = {
    position: "relative",
  };
  const subtitleStyles = { fontWeight: 200, letterSpacing: "-0.25rem" };
  const iconStyles = {
    width: "1rem",
    position: "absolute",
    top: "-1.6rem",
    left: 0,
  };

  return (
    <Typography variant="h1" style={wrapperStyles}>
      {!!programId ? (
        <Link
          onClick={() => {
            navigate("/activities");
          }}
          underline="hover"
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <FontAwesomeIcon icon={faArrowAltLeft} style={iconStyles} />{" "}
          Activities
          <span style={subtitleStyles}>{subtitle}</span>
        </Link>
      ) : (
        <span>
          Activities
          <span style={subtitleStyles}>{subtitle}</span>
        </span>
      )}
    </Typography>
  );
}
