import React, { useEffect, useState, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Paper,
  Button,
  Box,
  Typography,
  Grow,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Link,
  Grid,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScrollToTopOnMount } from "../../services/utilities";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  faThumbsUp,
  faThumbsDown,
  faHeart,
  faCheckCircle,
  faFlag,
  faCircleArrowLeft,
} from "@fortawesome/pro-solid-svg-icons";
import {
  useGetInterestsAndCategories,
  useInsertStudentInterest,
  useUpdateStudentInterest,
} from "../../services/hooks/interestsHook";
import { usePostEarnedBadge } from "../../services/hooks/badgesHook";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { getCurrentSchoolYear } from "../../services/utilities";
import { useGetBadgeDetailsForStudent } from "../../services/hooks/badgesHook";
import { blob } from "../../services/utilities";
import dayjs from "dayjs";

export default function InterestsRate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { category, interestId } = useParams();

  const [currentInterest, setCurrentInterest] = useState();
  const [filteredInterests, setFilteredInterests] = useState([]);
  const [unrankedInterests, setUnrankedInterests] = useState([]);
  const [interestsLoaded, setInterestsLoaded] = useState(false);
  const [ratedStudentInterest, setRatedStudentInterest] = useState();
  const [updatedRatedStudentInterest, setUpdateRatedStudentInterest] =
    useState();
  const [currentInterestView, setCurrentInterestView] = useState();
  const [badgeId, setBadgeId] = useState();
  const [showBadgeDialog, setShowBadgeDialog] = useState(false);
  const [allInterestBadge, setAllInterestBadge] = useState();
  const interestsResponse = useGetInterestsAndCategories();
  const interestData = interestsResponse?.data?.data;
  const { activeStudent } = useActiveStudent();
  const studentId = activeStudent?.studentId;

  const currentSchoolYear = useMemo(() => {
    return getCurrentSchoolYear();
  }, []);

  const earnedBadgeId = useMemo(() => {
    let bid;
    switch (category) {
      case "Academics":
        setBadgeId(56);
        bid = 56;
        break;
      case "Activities & Athletics":
        setBadgeId(57);
        bid = 57;
        break;
      case "Culture & Travel":
        setBadgeId(58);
        bid = 58;
        break;
      case "Entertainment & Hobbies":
        setBadgeId(59);
        bid = 59;
        break;
      default:
        return null;
    }
    return bid;
  }, [category]);

  const badgeResponse = useGetBadgeDetailsForStudent(earnedBadgeId);
  const badgeDetail = badgeResponse?.data?.data;

  let badgeAllResponse = useGetBadgeDetailsForStudent(62);
  const badgeAllDetail = useMemo(() => {
    return badgeAllResponse?.data?.data;
  }, [badgeAllResponse]);

  const badge = {
    studentId: studentId,
    badgeId: badgeId,
    schoolYear: currentSchoolYear,
    dateReceived: new Date().toLocaleDateString(),
  };

  const badgeAll = {
    studentId: studentId,
    badgeId: 62, // badge earned for completing all interests
    schoolYear: currentSchoolYear,
    dateReceived: new Date().toLocaleDateString(),
  };

  const insertEarnedBadgeAll = usePostEarnedBadge(badgeAll, () => {
    setShowBadgeDialog(true);
  });

  const awardAllInterestBadge = () => {
    insertEarnedBadgeAll.mutate();
  };

  useEffect(() => {
    if (interestsResponse?.data?.data?.interests && interestsLoaded === false) {
      setFilteredInterests(() => {
        const interests = interestsResponse?.data?.data?.interests.filter(
          (i) => {
            if (!!interestId) {
              return parseInt(i?.interestId) === parseInt(interestId);
            } else {
              return (
                i?.category === category && i?.studentInterests?.length === 0
              );
            }
          }
        );
        setInterestsLoaded(true);
        return interests;
      });
    }
  }, [interestsResponse, category, interestsLoaded, interestId]);

  useEffect(() => {
    if (interestsLoaded === true && filteredInterests) {
      setUnrankedInterests([...filteredInterests]);
    }
  }, [interestsLoaded, filteredInterests]);

  useEffect(() => {
    if (unrankedInterests.length === 0) {
      return;
    }
    setCurrentInterest(unrankedInterests.shift());
  }, [unrankedInterests]);

  useEffect(() => {
    if (currentInterest) {
      //set currentInterest to null to hide view
      setCurrentInterestView();
      // using timeout to trigger slide for skip
      // skip does not trigger the side without the setTimeout
      // using -1 will trigger the function immediately
      setTimeout(() => setCurrentInterestView(currentInterest), -1);
    }
  }, [currentInterest]);

  const commonPaddingStyle = {
    padding: theme?.spacing(0.5),
    paddingBottom: theme?.spacing(0.25),
  };

  const mainHeadingStyle = {
    background: `${theme?.palette?.background?.neutral} !important`,
    ...commonPaddingStyle,
  };

  const card = {
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      [theme.breakpoints.down("xs")]: {
        overflow: "scroll",
        width: "600px",
        height: "400px",
      },
      [theme.breakpoints.up("xs")]: {
        height: "auto",
        width: "600px",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
      height: "auto",
    },
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    textAlign: "center",
    maxWidth: `calc(100vw - ${theme.spacing(2)})`,
  };

  const ratingPlaceHolder = { paddingTop: 25 };

  const cardActions = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  };

  const badgeStyles = {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      maxWidth: 350,
    },
  };

  const iconFooterStyles = {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const earnedStyles = {
    ...iconFooterStyles,
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    color: theme?.palette?.secondary?.text,
    "& span": {
      display: "inline-block",
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
    },
  };

  const handleSkip = () => {
    unrankedInterests.push(currentInterest);
    setCurrentInterest(unrankedInterests.shift());
  };

  const createStudentInterest = async (rating) => {
    const now = new Date().toLocaleDateString();
    const studentInterest = {
      createdDate: now,
      interestId: currentInterest?.interestId,
      modifiedDate: now,
      rating: rating,
      studentId: activeStudent?.studentId,
    };
    setRatedStudentInterest(studentInterest);
  };

  const createUpdatedStudentInterest = async (rating) => {
    let si = currentInterest?.studentInterests[0];

    const studentInterest = {
      studentInterestId: si?.studentInterestId,
      createdDate: si?.createdDate,
      interestId: si?.interestId,
      modifiedDate: new Date().toLocaleDateString(),
      rating: rating,
      studentId: si?.studentId,
    };
    setUpdateRatedStudentInterest(studentInterest);
  };

  const triggerSubmit = (rating) => {
    createStudentInterest(rating).then(() => {
      insertStudentInterest.mutate();
    });
  };

  const update = (updatedRating) => {
    createUpdatedStudentInterest(updatedRating).then(() => {
      updateStudentInterest.mutate();
    });
  };

  const insertStudentInterest = useInsertStudentInterest(
    ratedStudentInterest,
    () => {
      if (unrankedInterests.length === 0) {
        insertEarnedBadge.mutateAsync().then((response) => {
          interestData.earnedBadges = [
            ...interestData.earnedBadges,
            response.data,
          ];
          if (
            interestData?.interestsCategories?.length ===
            interestData?.earnedBadges?.length
          ) {
            awardAllInterestBadge();
            badgeAllDetail.earned = [
              ...badgeAllDetail.earned,
              { dateReceived: dayjs().format("M/D/YYYY") },
            ];
            setAllInterestBadge(badgeAllDetail);
          }
        });
      }
      setCurrentInterest(unrankedInterests.shift());
      setRatedStudentInterest(null);
    }
  );

  const insertEarnedBadge = usePostEarnedBadge(badge, () => {
    badgeDetail.earned = [
      ...badgeDetail.earned,
      { dateReceived: dayjs().format("M/D/YYYY") },
    ];
  });

  const updateStudentInterest = useUpdateStudentInterest(
    updatedRatedStudentInterest,
    () => {
      navigate("/interests");
    }
  );

  const allowEdit = !!interestId;

  return (
    <>
      <Box>
        <Typography variant="h1" component="h2" gutterBottom>
          Interests
        </Typography>

        <Button
          component={RouterLink}
          to="/interests"
          startIcon={<FontAwesomeIcon icon={faCircleArrowLeft} />}
        >
          &nbsp;All Interests
        </Button>

        {filteredInterests?.length > 0 &&
        currentInterest &&
        currentInterestView ? (
          <Box sx={card}>
            <Grow in={true} timeout={600}>
              <Card sx={card}>
                <Paper square sx={mainHeadingStyle} elevation={0}>
                  <CardHeader title={category} />
                </Paper>
                <CardContent>
                  <Typography variant="h3" component="h4">
                    {currentInterest.name}
                  </Typography>
                  {currentInterest.description && (
                    <Typography size="small" variant="subtitle2">
                      {currentInterest.description}
                    </Typography>
                  )}
                  {currentInterest.imageUrl && (
                    <Link
                      target="_blank"
                      href={currentInterest.link}
                      rel="noopener"
                      color="inherit"
                      underline="always"
                    >
                      <img
                        src={currentInterest.imageUrl}
                        alt={`${currentInterest.name}`}
                      />
                    </Link>
                  )}
                  <div style={{ ...ratingPlaceHolder }}>
                    <PlaceholderScale
                      onClick={triggerSubmit}
                      onUpdate={update}
                      allowEdit={allowEdit}
                      rating={currentInterest?.studentInterests[0]?.rating}
                      isLoading={
                        insertStudentInterest.isLoading ||
                        updateStudentInterest.isLoading
                      }
                    />
                  </div>
                </CardContent>
                <CardActions sx={cardActions}>
                  {allowEdit ? (
                    <Box sx={iconFooterStyles}>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<FontAwesomeIcon icon={faFlag} />}
                        component={RouterLink}
                        to={`/goals/interests/${currentInterest?.interestId}`}
                      >
                        Make it a Goal
                      </Button>
                    </Box>
                  ) : (
                    <Button onClick={handleSkip}>skip</Button>
                  )}
                </CardActions>
              </Card>
            </Grow>
          </Box>
        ) : (
          badgeDetail && (
            <Grow in={true} timeout={600}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Box sx={badgeStyles}>
                    <img src={badgeDetail?.url} alt="" />
                  </Box>
                  {badgeDetail?.earned?.length && (
                    <Box sx={earnedStyles}>
                      <Typography>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        &nbsp;<strong>EARNED</strong>
                      </Typography>
                      <Typography variant="caption" component="div">
                        {badgeDetail?.earned?.map((eb) => {
                          return (
                            <span>
                              {dayjs(eb.dateReceived).format("M/D/YYYY")}
                            </span>
                          );
                        })}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grow>
          )
        )}
      </Box>
      <Dialog open={showBadgeDialog} onClose={() => setShowBadgeDialog(false)}>
        <DialogTitle>Is that everything? Congratulations...it is!</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} lg={6}>
              <Box sx={badgeStyles}>
                <img src={allInterestBadge?.url} alt="" />
              </Box>
              {allInterestBadge?.earned?.length && (
                <Box sx={earnedStyles}>
                  <Typography>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;<strong>EARNED</strong>
                  </Typography>
                  <Typography variant="caption" component="div">
                    {allInterestBadge?.earned?.map((eb) => {
                      return (
                        <span>{dayjs(eb.dateReceived).format("M/D/YYYY")}</span>
                      );
                    })}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBadgeDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * Scale component that takes the current rating of an item and displays all icons
 * if a rating has been chosen for an item it will be large and blue
 * rating can be null
 * @param {*} props
 */
export const PlaceholderScale = (props) => {
  const { rating, onClick, onUpdate, allowEdit, isLoading } = props;
  const theme = useTheme();

  const flexGrid = {
    display: "flex",
    justifyContent: "center",
  };

  const fabRow = {
    display: "flex",
    padding: theme.spacing(1),
    paddingBottom: 0,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 88,
  };

  const regularFab = {
    alignSelf: "center",
  };

  const labelRow = {
    display: "flex",
    padding: theme.spacing(1),
    paddingTop: 0,
    justifyContent: "center",
    alignItems: "center",
  };

  const ratingIcon = {
    fontSize: "1.5em",
    maxWidth: 28,
  };

  const thumbsDownIcon = {
    marginTop: 3,
    color: theme?.palette?.interests?.thumbs,
  };

  const dontKnowIcon = { margin: 6 };

  const bookReaderIcon = { margin: 7 };

  const thumbsUpIcon = {
    marginBottom: 3,
    color: theme?.palette?.interests?.thumbs,
  };

  const heartIcon = { color: theme?.palette?.interests?.heart };

  const ratingLabel = { fontSize: "0.8em" };

  return (
    <>
      <ScrollToTopOnMount />
      <Grid container item xs={12} sx={flexGrid}>
        {allowEdit ? (
          <Typography component="h5" variant="h6">
            <strong>Edit</strong>
          </Typography>
        ) : (
          <Typography component="h5" variant="h6">
            <strong>Rate it!</strong>
          </Typography>
        )}
      </Grid>
      <Grid container xs={12} sx={fabRow} spacing={2}>
        <Grid item xs={2} sx={regularFab}>
          <CustomFab
            currentRating={rating}
            onClick={allowEdit ? onUpdate : onClick}
            fabValue={1}
            isLoading={isLoading}
          >
            <FontAwesomeIcon
              icon={faThumbsDown}
              style={{ ...ratingIcon, ...thumbsDownIcon }}
            />
          </CustomFab>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <CustomFab
            currentRating={rating}
            onClick={allowEdit ? onUpdate : onClick}
            fabValue={2}
            isLoading={isLoading}
          >
            <img
              src={`${blob}Interests/dont-know.svg`}
              style={{ ...ratingIcon, ...dontKnowIcon }}
              alt="Don't know"
            />
          </CustomFab>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <CustomFab
            currentRating={rating}
            onClick={allowEdit ? onUpdate : onClick}
            fabValue={3}
            isLoading={isLoading}
          >
            <img
              src={`${blob}Interests/learn-more.svg`}
              style={{ ...ratingIcon, ...bookReaderIcon }}
              alt="Learn more"
            />
          </CustomFab>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <CustomFab
            currentRating={rating}
            onClick={allowEdit ? onUpdate : onClick}
            fabValue={4}
            isLoading={isLoading}
          >
            <FontAwesomeIcon
              icon={faThumbsUp}
              style={{ ...ratingIcon, ...thumbsUpIcon }}
            />
          </CustomFab>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <CustomFab
            currentRating={rating}
            onClick={allowEdit ? onUpdate : onClick}
            fabValue={5}
            isLoading={isLoading}
          >
            <FontAwesomeIcon
              icon={faHeart}
              style={{ ...ratingIcon, ...heartIcon }}
            />
          </CustomFab>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={labelRow} spacing={2}>
        <Grid item xs={2} sx={regularFab}>
          <Typography variant="inherit" sx={{ ...ratingLabel }}>
            Don't like!
          </Typography>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <Typography variant="inherit" sx={{ ...ratingLabel }}>
            Don't know
          </Typography>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <Typography variant="inherit" sx={{ ...ratingLabel }}>
            Want to
            <br />
            learn more
          </Typography>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <Typography variant="inherit" sx={{ ...ratingLabel }}>
            Like it
          </Typography>
        </Grid>
        <Grid item xs={2} sx={regularFab}>
          <Typography variant="inherit" sx={{ ...ratingLabel }}>
            Love it!
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
/**
 * Custom fab button used to display icons
 * @param {} props
 */
const CustomFab = (props) => {
  const { fabValue, isLoading, currentRating } = props;
  const selectedFab = {
    backgroundColor: "#fff !important",
    opacity: 1,
    fontSize: "1.5em",
  };

  const ratedFab = {
    opacity: 1,
    backgroundColor: "#e0e0e0 !important",
  };
  return (
    <Fab
      color={
        currentRating
          ? currentRating === fabValue
            ? "primary"
            : "inherit"
          : "inherit"
      }
      size={
        currentRating
          ? currentRating === fabValue
            ? "large"
            : "small"
          : "small"
      }
      onClick={() => props.onClick(fabValue)}
      disabled={isLoading || currentRating === fabValue}
      sx={
        currentRating
          ? currentRating === fabValue
            ? selectedFab
            : ratedFab
          : undefined
      }
    >
      {props.children}
    </Fab>
  );
};
