import React, { useState, useMemo, useRef, useCallback } from "react";
import dayjs from "dayjs";
import {
  useGetInfiniteMoods,
  useGetMoodsCount,
} from "../../services/hooks/moodsHook";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import ReactECharts from "echarts-for-react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import { blob } from "../../services/utilities";

export default function MyMoods(props) {
  const theme = useTheme();
  const { selectedMood, setSelectedMood } = props;
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);

  const moodsCountResponse = useGetMoodsCount();
  const moodColorCount = moodsCountResponse?.data?.data;
  const studentInfiniteMoodsQuery = useGetInfiniteMoods();
  const displayDetailMood = (mood) => {
    setSelectedMood(mood);
    setConfirmDialogIsOpen(true);
  };
  const handleConfirmClose = (mood) => {
    setConfirmDialogIsOpen(false);
  };

  const studentMoodsData = useMemo(() => {
    return studentInfiniteMoodsQuery?.data?.pages?.reduce(
      (total, val) => total.concat(val.data),
      []
    );
  }, [studentInfiniteMoodsQuery]);

  const observer = useRef();
  const lastMoodRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0]?.isIntersecting &&
          studentInfiniteMoodsQuery.hasNextPage
        ) {
          studentInfiniteMoodsQuery.fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [studentInfiniteMoodsQuery]
  );

  const dialogTitleColorStyles = [
    {
      background:
        theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.main,
      color:
        theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.contrastText,
    },
  ];
  const dialogContentColorStyles = [
    {
      background:
        theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.main,
      color:
        theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.contrastText,
    },
  ];
  const dialogTitleStyles = [
    {
      textTransform: "capitalize",
      paddingBottom: theme.spacing(1.5),
    },
  ];
  const confirmDialogTitleStyles = [
    ...dialogTitleStyles,
    ...dialogTitleColorStyles,
  ];
  const dialogHeadingStyles = {
    textAlign: "center",
    color: theme.palette.text.primaryAlt,
    textShadow: `4px 4px 20px ${theme.palette.text.primary}, -4px -4px 20px ${theme.palette.text.primary}, 0px 0px 40px ${theme.palette.text.primary}`,
  };
  const confirmDialogContentStyles = {
    marginTop: theme.spacing(3),
    "& .MuiGrid-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      ...dialogContentColorStyles,
    },
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    position: "absolute",
    right: 4,
    top: 4,
    zIndex: 1000,
    color:
      theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.contrastText,
  };

  const emojiStyles = {
    background: `url(${blob}Moods/mood-bg.svg) no-repeat top center`,
    width: "5rem",
    margin: theme.spacing(1),
  };
  const emojiSmallStyles = {
    ...emojiStyles,
    width: "2.5rem",
  };
  const emojiLargeStyles = {
    ...emojiStyles,
    width: "18rem",
  };
  const commonPaddingStyle = {
    padding: theme?.spacing(2),
    paddingBottom: theme?.spacing(1.5),
  };
  const mainHeadingStyle = {
    background: `${theme?.palette?.background?.neutral} !important`,
    ...commonPaddingStyle,
  };

  function titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const studentMoodChart = {
    title: {
      text: "Previous Moods",
      subtext: "Overall",
      left: "center",
      top: 0,
      textStyle: {
        color: theme?.palette?.text?.primary,
      },
      subtextStyle: {
        color: theme?.palette?.text?.secondary,
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "center",
      selectedMode: false,
      formatter: function (name) {
        return (
          name +
          " zone " +
          moodColorCount?.find(
            (moodColor) => name.toLowerCase() === moodColor?.color.toLowerCase()
          )?.count
        );
      },
      textStyle: {
        color: theme?.palette?.text?.primary,
      },
      subtextStyle: {
        color: theme?.palette?.text?.secondary,
      },
    },
    label: {
      textShadowColor: "transparent",
      color: theme?.palette?.text?.primary,
    },
    series: [
      {
        name: "Color Zone",
        type: "pie",
        radius: "52%",
        zlevel: -1,
        label: {
          show: false,
        },
        color: "white",
        data: [{ value: 100, name: "" }],
        emphasis: {
          itemStyle: {
            ShadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0,0,0,0.5)",
          },
        },
      },
      {
        name: "Color zone",
        type: "pie",
        radius: "50%",
        zlevel: 1,
        data: moodColorCount?.map((color) => {
          return {
            value: color?.count,
            name: titleCase(color?.color),
            itemStyle: {
              color: theme?.palette?.moods[`${color?.color}Zone`]?.main,
            },
          };
        }),
      },
    ],
    media: [
      {
        query: { maxWidth: 400 },
        option: {
          legend: {
            right: "center",
            bottom: 0,
            orient: "horizontal",
          },
          series: [
            {
              center: ["50%", "50%"],
              bottom: 50,
            },
            {
              center: ["50%", "50%"],
              bottom: 50,
            },
          ],
        },
      },
    ],
  };
  return (
    <>
      {studentMoodsData?.length > 0 && (
        <Paper>
          <Paper square sx={mainHeadingStyle} elevation={0}>
            <Typography component="h3" variant="h4">
              My Moods
            </Typography>
          </Paper>
          <Grid container spacing={3} sx={commonPaddingStyle}>
            <Grid item xs={12}>
              {studentMoodsData?.length > 1 && (
                <ReactECharts option={studentMoodChart} />
              )}
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid container spacing={3} sx={commonPaddingStyle}>
            <Grid item xs={12}>
              <Typography component="h3" variant="h4">
                Previous Mood List
                <Divider />
                <List>
                  {studentMoodsData?.map((mood, index) => {
                    return (
                      <span key={index} ref={lastMoodRef}>
                        <ListItem disablePadding key={index}>
                          <ListItemButton
                            onClick={() => displayDetailMood(mood)}
                          >
                            <div
                              style={{
                                height: "3.5rem",
                                borderRadius: "50%",
                                backgroundColor: mood?.mood?.color,
                              }}
                            >
                              <ListItemAvatar>
                                <img
                                  src={mood?.mood?.url}
                                  style={emojiSmallStyles}
                                  alt={mood?.mood?.name}
                                />
                              </ListItemAvatar>
                            </div>
                            <ListItemText
                              sx={{
                                paddingLeft: theme?.spacing(2),
                              }}
                              primary={mood?.mood?.name}
                              secondary={dayjs(`${mood?.createdDate}`).format(
                                "DD-MM-YY h:mm a"
                              )}
                            />
                            <ListItemSecondaryAction>
                              {mood?.rating === 2 && (
                                <Button
                                  variant="outlined"
                                  size="large"
                                  startIcon={
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                  }
                                  onClick={() => displayDetailMood(mood)}
                                >
                                  Tip&nbsp;
                                </Button>
                              )}
                              {mood?.rating === 1 && (
                                <Button
                                  variant="outlined"
                                  size="large"
                                  startIcon={
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                  }
                                  onClick={() => displayDetailMood(mood)}
                                >
                                  Tip&nbsp;
                                </Button>
                              )}
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                        <Divider />
                      </span>
                    );
                  })}
                </List>
              </Typography>
            </Grid>
          </Grid>

          <Dialog open={confirmDialogIsOpen} onClose={handleConfirmClose}>
            <DialogTitle sx={confirmDialogTitleStyles}>
              {selectedMood?.mood?.color} Zone
            </DialogTitle>
            <DialogContent sx={confirmDialogContentStyles}>
              <IconButton onClick={handleConfirmClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
              <Grid container>
                <Grid item xs={12}>
                  <img
                    src={selectedMood?.mood?.url}
                    style={emojiLargeStyles}
                    alt={selectedMood?.mood?.name}
                  />
                  <Typography
                    component="h1"
                    variant="h1"
                    sx={dialogHeadingStyles}
                  >
                    {selectedMood?.mood?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h2" variant="h6">
                    Previously viewed tip:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {selectedMood?.reflectionPrompt?.prompt}
                  </Typography>
                  <br></br>
                </Grid>
                {selectedMood?.rating !== 0 && (
                  <Grid item xs={12}>
                    <Typography component="h3" variant="h6">
                      You thought this tip was: &nbsp;
                      {selectedMood?.rating === 2 && (
                        <FontAwesomeIcon icon={faThumbsUp} />
                      )}
                      {selectedMood?.rating === 1 && (
                        <FontAwesomeIcon icon={faThumbsDown} />
                      )}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </Dialog>
          {studentInfiniteMoodsQuery.hasNextPage && (
            <Button
              variant="outlined"
              onClick={studentInfiniteMoodsQuery.fetchNextPage}
            >
              See more moods
            </Button>
          )}
        </Paper>
      )}
    </>
  );
}
