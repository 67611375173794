import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSpring, animated, config } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { useUser } from "../../services/contexts/userContext";
import { themes } from "../../themes";
import { useSelectedTheme } from "../../services/contexts/themeContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { GetAccessToken } from "../../services/apiService";
import { useSnackbar } from "notistack";
import ThemePickerExample from "../images/icons/ThemePicker";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function Personalization() {
  const commonStyles = useCommonStyles();
  const theme = useTheme();
  const { setActiveStudent } = useActiveStudent();
  const { user, setRole } = useUser();
  const { selectedTheme, setTheme } = useSelectedTheme();
  const snackbar = useSnackbar();

  const [accessToken, setAccessToken] = useState();
  const token = GetAccessToken();
  useEffect(() => {
    if (token) {
      token.then((res) => {
        setAccessToken(res.accessToken);
      });
    }
  }, [token]);

  const GetAvailableRoles = () => {
    let roles = [];

    if (user.isStudent) {
      roles.push("student");
    }
    if (user.isGuardian) {
      roles.push("guardian");
    }

    return roles;
  };

  // Styles
  const settingStyles = {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      "& h3": {
        textAlign: "right",
      },
      "& .MuiFormControl-root": {
        width: 200,
      },
    },
  };
  const profileStyles = {
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  };
  const avatarStyles = {
    bgcolor: theme.palette.neutral?.lightest,
    color: theme.palette.nav?.appBar,
    width: 200,
    height: 200,
    "& svg": {
      width: 190,
      height: 190,
    },
    marginBottom: "-2rem",
    zIndex: 999,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)", // like Paper elevation(4)
  };
  const paperStyles = {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    marginBottom: theme.spacing(6),
  };
  const themeButtonStyles = {
    display: "flex",
    flexDirection: "column",
  };
  const emailStyles = {
    maxWidth: "100%",
    fontSize: "2em",
  };

  // Animations
  const avatarSpringProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -500 },
    delay: 200,
    config: config.gentle,
  });
  const paperSpringProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 600,
    config: config.gentle,
  });
  const AnimatedAvatar = animated(Avatar);
  const AnimatedPaper = animated(Paper);

  return (
    <Box>
      <Typography variant="h1" component="h2" gutterBottom>
        Personalization
      </Typography>

      {/* Profile */}
      <Grid container sx={profileStyles}>
        <Grid item>
          <AnimatedAvatar
            style={avatarSpringProps}
            alt="My avatar"
            sx={avatarStyles}
          >
            <FontAwesomeIcon icon={faUserCircle} />
          </AnimatedAvatar>
        </Grid>
        {!!user && (
          <>
            <AnimatedPaper
              style={paperSpringProps}
              sx={paperStyles}
              elevation={2}
            >
              <Typography component="h3" variant="h2">
                {user.name}
              </Typography>
              <Typography component="p" variant="body1" sx={emailStyles}>
                {user.email}
              </Typography>
            </AnimatedPaper>
            {user.isEmployee && (
              <Grid item>
                <Typography component="p" variant="body1">
                  Edit your profile and see more details...
                </Typography>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  href="https://delve.office.com"
                  target="_blank"
                >
                  Office 365
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>

      {/* Role */}
      {GetAvailableRoles().length > 1 && (
        <Grid container spacing={3} sx={settingStyles}>
          <Grid item xs={12} sm={5} md={4}>
            <Typography variant="h4" component="h3">
              Acting Role:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <FormControl fullWidth>
              <InputLabel id="theme-select-label">Role</InputLabel>
              <Select
                labelId="theme-select-label"
                id="theme-select"
                value={user?.role}
                label="Role"
                onChange={(e) => {
                  setRole(e.target.value);
                  setActiveStudent(null);
                }}
              >
                {GetAvailableRoles().map((r, index) => (
                  <MenuItem key={index} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      {/* theme */}
      <>
        <Container maxWidth="md">
          <Typography variant="h4" component="h3">
            Choose a Theme:
          </Typography>
          <Paper sx={commonStyles?.paperPadding}>
            <Grid container spacing={1} justifyContent="space-around">
              {themes.map((tn, i) => {
                return (
                  <Grid item key={i}>
                    <Box>
                      <Button
                        variant={selectedTheme?.name === tn.name && "contained"}
                        onClick={() => {
                          setTheme(tn?.name);
                        }}
                        sx={[
                          { color: theme?.palette?.text?.primary },
                          themeButtonStyles,
                        ]}
                      >
                        <Typography
                          variant="h5"
                          color={
                            selectedTheme?.name === tn.name
                              ? theme?.palette?.primary?.contrastText
                              : theme?.palette?.text?.primary
                          }
                          sx={{
                            fontFamily: tn?.typography?.h5?.fontFamily,
                            textTransform: "capitalize",
                          }}
                        >
                          {tn.name}
                        </Typography>
                        <ThemePickerExample theme={tn} />
                      </Button>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Container>
      </>

      {(process.env.REACT_APP_ENVIRONMENT === "local" ||
        process.env.REACT_APP_ENVIRONMENT === "development") && (
        <>
          <Grid container spacing={3} sx={settingStyles}>
            <Grid item xs={12} sm={5} md={4}>
              <Typography variant="h4" component="h3">
                Token:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Typography
                  component="div"
                  variant="caption"
                  style={{
                    width: 200,
                    height: 74,
                    padding: "0.5rem",
                    overflow: "hidden",
                    borderRadius: 4,
                    border: "1px solid rgba(200,200,200,0.25)",
                  }}
                >
                  {accessToken}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigator.clipboard.writeText(accessToken);
                    snackbar.enqueueSnackbar(`Copied`, {
                      variant: "success",
                      autoHideDuration: 1000,
                    });
                  }}
                >
                  Copy
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
